import { Image, Platform, StyleSheet, View } from 'react-native'
import { reloadAsync } from 'expo-updates'
import camelCase from 'lodash.camelcase'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button'
import Text, { H1, P } from '@/components/Text'

import { Colors, Space } from '@/utils/constants/theme'

type Props = {
  error?: Error | any
  title?: string
  message?: string
  action?: { text: string; onPress: () => void }
}

const environment = process.env.EXPO_PUBLIC_ENVIRONMENT

function AppError(props: Props) {
  const { t } = useTranslation()

  function handleTryAgain() {
    if (Platform.OS === 'web') {
      window.location.reload()
    } else {
      reloadAsync()
    }
  }

  return (
    <View style={styles.container}>
      <H1>
        {props.title ??
          t('screens.error.title', {
            context: camelCase(props.error?.message),
          })}
      </H1>
      <P align="center">
        {props.message ??
          t('screens.error.body', { context: camelCase(props.error?.message) })}
      </P>
      <Image
        resizeMode="contain"
        source={require('./surprised.png')}
        style={styles.image}
      />
      {Boolean(props.error?.message && environment !== 'production') && (
        <Text style={styles.message}>
          {props.error.message ?? ''} (dev only)
        </Text>
      )}
      {Boolean(props.action) && (
        <Button
          onPress={props.action?.onPress ?? handleTryAgain}
          style={{ marginTop: 16 }}
        >
          {props.action?.text ?? t('screens.error.action')}
        </Button>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.light.backgroundContent,
    flex: 1,
    gap: Space.Small.n,
    justifyContent: 'center',
    padding: Space.Medium.n,
  },
  image: { height: 150, width: 150 },
  message: { marginBottom: Space.Medium.n, textAlign: 'center' },
})

export default AppError
