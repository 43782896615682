import { useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import type { ComponentProps } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'

import Text from '@/components/Text'

const styles = StyleSheet.create({
  container: { flex: 1 },
  content: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    // backgroundColor: '#f1f1f8',
  },
  dummyBottomBar: { backgroundColor: '#FFFFFF', height: 48, width: '100%' },
  dummyTopBar: { backgroundColor: '#7664EF', height: 48, width: '100%' },
})

export function FullScreenLoading({
  style,
  message,
  ...props
}: ComponentProps<typeof View> & { message?: string }) {
  const [showActivityIndicator, setShowActivityIndicator] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowActivityIndicator(true)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 500)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <View style={[styles.container, style]} {...props}>
      <SafeAreaView edges={['top']} style={styles.dummyTopBar} />
      <View style={styles.content}>
        {Boolean(message) && <Text>{message}</Text>}
        {Boolean(showActivityIndicator) && <ActivityIndicator size="large" />}
      </View>
      <SafeAreaView edges={['bottom']} style={styles.dummyBottomBar} />
    </View>
  )
}
