import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { useCustomerId } from '@/utils/zustand'

import {
  getApplications,
  getCreditReport,
  getCustomer,
  getCustomerDocuments,
  getCustomerReferralLink,
  getCustomerTasks,
} from './api'

export const customerKeys = {
  all: ['customers'] as const,
  applications: (id: string | null, options?: Record<string, any>) =>
    [...customerKeys.detail(id), 'applications', options] as const,
  creditBureauReports: (id: string | null) =>
    [...customerKeys.detail(id), 'credit-bureau-reports'] as const,
  detail: (id: string | null) => [...customerKeys.all, 'detail', id] as const,
  documents: (id: string | null, options?: Record<string, any>) =>
    [...customerKeys.detail(id), 'documents', options] as const,
  paymentAccounts: (id: string | null, options?: Record<string, any>) =>
    [...customerKeys.detail(id), 'payment-accounts', options] as const,
  payments: (id: string | null) =>
    [...customerKeys.detail(id), 'payments'] as const,
  products: (id: string | null) =>
    [...customerKeys.detail(id), 'products'] as const,
  referralLink: (id: string | null) =>
    [...customerKeys.detail(id), 'referral-link'] as const,
  schedule: (id: string | null) =>
    [...customerKeys.detail(id), 'schedule'] as const,
  self: () => [...customerKeys.all, 'self'] as const,
  task: (id: string | null, taskId: string) =>
    [...customerKeys.tasks(id), taskId] as const,
  tasks: (id: string | null) => [...customerKeys.detail(id), 'tasks'] as const,
}

export type GetCustomerResponse = AxiosResponse<{
  id: string
  givenName: string
  timezone?: string
  middleName: string
  familyName: string
  taxIdType?: string
  taxIdDisplay?: string
  preferredLanguage?: string
  addresses?: { postalCode: string }[]
  emails?: { id: string; address: string }[]
  phoneNumbers?: { id: string; number: string }[]
}>

export function useSelfCustomer(
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCustomer>>,
    AxiosError<unknown>
  >,
) {
  const customerId = useCustomerId()

  return useQuery({
    enabled: Boolean(customerId),
    queryFn: () => getCustomer(customerId),
    queryKey: customerKeys.detail(customerId),
    ...options,
  })
}

export function useCustomerDocuments(
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  params?: Parameters<typeof getCustomerDocuments>[1],
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerDocuments>>,
    AxiosError<unknown>
  >,
) {
  const customerId = useCustomerId()

  return useQuery({
    queryFn: () => getCustomerDocuments(customerId, params),
    queryKey: customerKeys.documents(customerId, params),
    ...options,
  })
}

export function useApplications(
  params?: { unexpire?: boolean },
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getApplications>>,
    AxiosError<unknown>
  >,
) {
  const customerId = useCustomerId()

  return useQuery({
    queryFn: () => getApplications(customerId, params),
    queryKey: customerKeys.applications(customerId, params),
    ...options,
    enabled: (options?.enabled ?? true) && Boolean(customerId),
  })
}

export function useCreditReport(
  customerId: string | null,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCreditReport>>,
    AxiosError<unknown>
  >,
) {
  return useQuery({
    queryFn: () => getCreditReport(customerId),
    queryKey: customerKeys.creditBureauReports(customerId),
    ...options,
  })
}

export function useTasks(
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerTasks>>,
    AxiosError<unknown>
  >,
) {
  const customerId = useCustomerId()

  return useQuery({
    queryFn: () => getCustomerTasks(customerId),
    queryKey: customerKeys.tasks(customerId),
    ...options,
  })
}

export function useReferralLink(
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerReferralLink>>,
    AxiosError<unknown>
  >,
) {
  const customerId = useCustomerId()

  return useQuery({
    queryFn: () => getCustomerReferralLink(customerId),
    queryKey: customerKeys.referralLink(customerId),
    ...options,
  })
}
