import { StyleSheet } from 'react-native'
import type { SvgProps } from 'react-native-svg'
import Svg, { Path } from 'react-native-svg'

const defaultSize = 108

const Ocho = ({
  style,
  size = defaultSize,
  ...props
}: SvgProps & { fill?: string; size?: number }) => (
  <Svg
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    style={[styles.container, { height: size / 4.32, width: size }, style]}
    viewBox="11 0 108 25"
    {...props}
  >
    <Path
      d="M23.488 15.585a3.1 3.1 0 0 1-3.084-3.085 3.1 3.1 0 0 1 3.085-3.085h.001a3.1 3.1 0 0 1 3.084 3.085 3.1 3.1 0 0 1-3.085 3.085h-.001M23.486 0c-6.903 0-12.499 5.596-12.499 12.5 0 6.903 5.596 12.5 12.499 12.5 6.904 0 12.499-5.597 12.499-12.5C35.985 5.596 30.39 0 23.486 0m79.908 15.585a3.1 3.1 0 0 1-3.085-3.085 3.1 3.1 0 0 1 3.085-3.085 3.1 3.1 0 0 1 3.085 3.085 3.1 3.1 0 0 1-3.085 3.085m0-15.585c-6.903 0-12.499 5.597-12.499 12.5S96.491 25 103.394 25c6.903 0 12.499-5.597 12.499-12.5S110.297 0 103.394 0M50.152 15.594h-.002a3.1 3.1 0 0 1-3.085-3.085 3.1 3.1 0 0 1 3.085-3.085c1.233 0 2.352.739 2.837 1.872h9.605C61.986 4.957 56.648 0 50.152 0c-6.903 0-12.499 5.596-12.499 12.5 0 6.903 5.596 12.5 12.499 12.5 6.496 0 11.834-4.958 12.44-11.296h-9.596a3.09 3.09 0 0 1-2.844 1.89M77.987.225l-.023 10.805h-2.412L75.576.225H64.379l-.023 24.573h11.196V13.986h2.412v10.812H89.16V.202L77.987.225Zm38.562 2.438h.365l.242.581h.656l-.331-.71c.235-.133.365-.369.365-.69 0-.495-.307-.789-.782-.789h-1.099v2.189h.584v-.581Zm-1.926-.467c0 1.301.887 2.195 2.182 2.195 1.294 0 2.182-.894 2.182-2.195 0-1.298-.892-2.196-2.182-2.196-1.288 0-2.182.898-2.182 2.196Zm.549 0c0-1.004.646-1.663 1.633-1.663.986 0 1.632.659 1.632 1.663 0 1-.646 1.659-1.632 1.659-.987 0-1.633-.655-1.633-1.659Zm1.377-.069v-.505h.464c.16 0 .253.075.253.236 0 .167-.086.269-.246.269h-.471Z"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{ fill: props.fill ?? '#fff' }}
    />
  </Svg>
)

const styles = StyleSheet.create({
  container: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    clipRule: 'evenodd',
    fillRule: 'evenodd',
    strokeLinejoin: 'round',
    strokeMiterlimit: 2,
  },
})

export default Ocho
