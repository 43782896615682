import 'react-native-get-random-values'
import 'react-native-url-polyfill/auto'

/* eslint-disable func-style */

// Promise allSettled polyfill
if (!Promise.allSettled) {
  Promise.allSettled = (promises: Promise<any>[]): any =>
    Promise.all(
      promises.map((promise) =>
        promise
          .then((value: any) => ({ status: 'fulfilled', value }))
          .catch((reason: any) => ({ reason, status: 'rejected' })),
      ),
    )
}

if (typeof globalThis === 'undefined') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.globalThis = global
}

/**
 * Array.flat() polyfill
 * Adapted from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#reduce_concat_isArray_recursivity
 */
if (!Array.prototype.flat) {
  Array.prototype.flat = function (depth: any) {
    'use strict'

    // If no depth is specified, default to 1
    if (depth === undefined) {
      depth = 1
    }

    // Recursively reduce sub-arrays to the specified depth
    const flatten = function (arr: any, depth: any) {
      // If depth is 0, return the array as-is
      if (depth < 1) {
        return arr.slice()
      }

      // Otherwise, concatenate into the parent array
      return arr.reduce(function (acc: any, val: any) {
        return acc.concat(Array.isArray(val) ? flatten(val, depth - 1) : val)
      }, [])
    }

    return flatten(this, depth)
  }
}
