import { AnalyticsBrowser } from '@segment/analytics-next'

import {
  identify as phIdentify,
  posthogClient,
  screen,
} from '@/utils/analytics/posthog'
import { isDevelopment } from '@/utils/constants'
import Sentry from '@/utils/sentry'
import {
  getCurrentApplicationId,
  getCustomerId,
  getUserId,
  useAuthStore,
} from '@/utils/zustand'

import type { AnalyticsEvent, IdentifyArgs } from './utils'
import { appInformation } from './utils'

const segmentId = process.env.EXPO_PUBLIC_SEGMENT_ID as string

window?.addEventListener('appinstalled', () => {
  track({ id: 'ui__app_installed' })
})

let segmentClient: undefined | AnalyticsBrowser

declare global {
  interface Window {
    gtag: (action: string, scope: string, data: any) => void
  }
}

export function initialize() {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log('Not initializing analytics in development')
    return
  }

  try {
    if (!segmentId) throw new Error('Missing segment id')

    segmentClient = AnalyticsBrowser.load({ writeKey: segmentId })
  } catch (error) {
    Sentry.captureException(error)
  }

  try {
    posthogClient?.register(appInformation)
  } catch (error) {
    Sentry.captureException(error)
  }
}

/**
 * The Alias method is an advanced method used to merge 2 unassociated user identities,
 * effectively connecting 2 sets of user data in one profile.
 */
export async function alias(currentUserId: string, previousUserId: string) {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log(`Not aliasing user "${previousUserId}" in development`, {
      currentUserId,
      previousUserId,
    })
    return
  }

  if (!currentUserId || !previousUserId) {
    return
  }

  try {
    await Promise.allSettled([
      phIdentify(currentUserId),
      segmentClient?.alias(currentUserId, previousUserId),
    ])
  } catch (error) {
    Sentry.captureException(error)
  }
}

export async function identify(
  { userId = getUserId(), ...userInformation }: IdentifyArgs = {
    userId: getUserId(),
  },
  options: { omitPosthog?: boolean } = { omitPosthog: false },
) {
  // Sentry user context
  try {
    const store = useAuthStore.getState()
    if (store.customerId) {
      Sentry.setTag('customerId', store.customerId)
    }
    if (store.currentApplicationId) {
      Sentry.setTag('applicationId', store.currentApplicationId)
    }
    if (store.user?.userId) {
      Sentry.setUser({ username: store?.user?.signInDetails?.loginId })
    }
  } catch (error) {
    Sentry.captureException(error)
  }

  if (isDevelopment && userId) {
    // eslint-disable-next-line no-console
    console.log(`Not identifying user "${userId}" in development`, {
      metadata: userInformation,
      userId,
    })
    return
  }

  try {
    let gender = userInformation.gender?.toLowerCase()
    gender = gender === 'unspecified' ? 'unisex' : gender

    /* eslint-disable sort-keys-fix/sort-keys-fix */
    let traits = {
      ...userInformation,
      gender,
      // special casing for gtag
      first_name: userInformation.firstName,
      last_name: userInformation.lastName,
      phone_number: userInformation.phone,
    }
    /* eslint-enable sort-keys-fix/sort-keys-fix */

    // Remove empty values
    traits = Object.fromEntries(
      Object.entries(traits).filter(([, value]) => Boolean(value)),
    ) as typeof traits

    if (Object.keys(traits).length === 0) {
      return
    }

    // Product analytics
    await Promise.allSettled([
      segmentClient?.identify(getCustomerId(), traits, {
        anonymousId: getCurrentApplicationId() ?? undefined,
      }),
      segmentClient?.ready(() => window.gtag('set', 'user_data', traits)),
      options.omitPosthog || !userId
        ? Promise.resolve()
        : phIdentify(userId, traits),
    ])
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function track(event: AnalyticsEvent) {
  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log(`Not tracking event "${event.id}" in development`, { event })
    return
  }

  try {
    Promise.allSettled([
      segmentClient?.track(event.id, {
        ...(event?.metadata ?? {}),
        ...appInformation,
      }),
      posthogClient?.capture(event.id, event.metadata),
    ])
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function screenView(pageName: string, metadata = {}) {
  if (isDevelopment) return

  try {
    Promise.allSettled([
      segmentClient?.page(pageName, metadata),
      screen(pageName, metadata),
    ])
  } catch (error) {
    Sentry.captureException(error)
  }
}
