import {
  Children,
  cloneElement,
  type ComponentProps,
  isValidElement,
  type ReactNode,
} from 'react'
import type { Pressable } from 'react-native'
import type { TextStyle } from 'react-native'

import { Colors } from '@/utils/constants/theme'

import * as Styled from './styled'

type ButtonType =
  | 'default'
  | 'secondary'
  | 'disabled'
  | 'destructive'
  | 'transparent'

type Props = {
  children: ReactNode
  type?: ButtonType
  textStyle?: TextStyle
  disabled?: boolean
  $padding?: string
  $disabled?: boolean
  $isPlaceholder?: boolean
  /** @default primary */
  color?: string
  /**
   * Should not be used, since we are calculating
   * the best color based on the buttons background
   */
  textColor?: string
  /** @deprecated use type instead */
  transparent?: boolean
  /** @deprecated use Button.Icon instead */
  icon?: ReactNode
  $small?: boolean
  isBottomButton?: boolean
  /** @deprecated use Button.Text instead */
  textProps?: ComponentProps<typeof Styled.Text>
} & Omit<ComponentProps<typeof Pressable>, 'ref'>

const smallButtonHitSlop = {
  bottom: 4,
  left: 6,
  right: 6,
  top: 4,
} as const

function Button(props: Props) {
  const {
    icon,
    type,
    color,
    $padding,
    children,
    textStyle,
    style,
    $small,
    textProps,
    onLongPress,
    $isPlaceholder,
    disabled = false,
    $disabled = false,
    transparent = Boolean(type === 'transparent' || $isPlaceholder),
    isBottomButton = false,
    onPress = () => undefined,
    textColor,
    ...otherProps
  } = props

  const commonProps = {
    $color: color,
    $disabled: disabled || $disabled,
    $transparent: transparent,
    $type: type,
  }

  return (
    <Styled.Container
      style={({ pressed }) => ({
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        opacity: pressed ? 0.666 : 1,
        ...(style as any),
      })}
      $isBottomButton={isBottomButton}
      $isPlaceholder={$isPlaceholder}
      accessibilityState={{ disabled }}
      disabled={disabled}
      onLongPress={onLongPress}
      onPress={onPress}
      role="button"
      {...commonProps}
      {...otherProps}
    >
      <Styled.Content
        $padding={($padding ?? isBottomButton) ? '15px 0' : undefined}
        $small={$small}
        hitSlop={$small ? smallButtonHitSlop : undefined}
        {...commonProps}
      >
        {typeof children === 'string' ? (
          <>
            {icon}
            <Styled.Text
              style={textStyle}
              {...commonProps}
              {...textProps}
              $textColor={textColor}
            >
              {children}
            </Styled.Text>
          </>
        ) : (
          Children.map(children, (child) =>
            isValidElement(child)
              ? cloneElement(child, {
                  ...child.props,
                  ...commonProps,
                  ...textProps,
                })
              : child,
          )
        )}
      </Styled.Content>
    </Styled.Container>
  )
}

Button.Icon = Styled.Text

Button.Text = Styled.Text

export const iconProps = {
  color: Colors.light.secondary,
  size: 18,
  strokeWidth: 2.5,
} as const

export { ButtonType }
export default Button
