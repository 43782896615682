import { Animated } from 'react-native'
import styled from 'styled-components/native'

import { CornerRadius, maxWidth } from '@/utils/constants/theme'

export const ALERT_MAX_WIDTH = 400

export const Content = styled(Animated.View)<{
  $isFullSize?: boolean
  $isMidSize?: boolean
  $isMobile?: boolean
  $isAlert?: boolean
  /** @deprecated */
  $variant?: 'morado'
}>`
  flex: ${({ $isFullSize, $isMidSize }) =>
    $isMidSize ? '0 1 70%' : $isFullSize ? '1' : '0 1 auto'};
  overflow: hidden;
  margin: ${({ $isMobile }) => ($isMobile ? 'auto auto 0 auto' : 'auto')};
  width: 100%;
  height: ${({ $isMobile, $isAlert }) =>
    $isMobile ? 'auto' : $isAlert ? 'auto' : '100%'};
  max-height: ${({ $isMobile }) =>
    $isMobile ? 'auto' : `${maxWidth.phone}px`};
  max-width: ${({ $isAlert }) =>
    $isAlert ? ALERT_MAX_WIDTH : maxWidth.smallPhone}px;
  background-color: ${({ $variant, theme }) =>
    $variant === 'morado' ? theme.colors.primary : theme.colors.background};
  border-radius: ${CornerRadius.Xlarge.px};
`

export const Container = styled(Animated.View)`
  bottom: 0;
  flex: 1;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`
