import { useEffect } from 'react'
import { Animated, Platform, Pressable, StyleSheet } from 'react-native'
import Feather from '@expo/vector-icons/Feather'
import { SafeAreaView } from 'react-native-safe-area-context'
import styled from 'styled-components/native'

import Text from '@/components/Text'

import { CornerRadius, Space } from '@/utils/constants/theme'
import { BANNER } from '@/utils/constants/zIndex'
import { useBannersStore } from '@/utils/zustand'

const NOTIFICATION_HEIGHT = 150

const BannerContent = styled.View`
  padding: ${Space.Medium.px};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${CornerRadius.Xlarge.px};
  max-width: 300px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`

const NotificationsContainer = styled(SafeAreaView)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${Space.Small.px};
`

function Banners() {
  const [notifications, removeNotification] = useBannersStore(
    ({ current, remove }) => [current, remove],
  )
  const [currentNotification] = notifications
  const animated = new Animated.Value(-NOTIFICATION_HEIGHT)

  useEffect(() => {
    if (currentNotification) {
      Animated.sequence([
        Animated.spring(animated, {
          toValue: 0,
          useNativeDriver: Platform.OS !== 'web',
        }),
        Animated.timing(animated, {
          duration: currentNotification.duration,
          toValue: 0,
          useNativeDriver: Platform.OS !== 'web',
        }),
        Animated.spring(animated, {
          toValue: -NOTIFICATION_HEIGHT,
          useNativeDriver: Platform.OS !== 'web',
        }),
      ]).start(() => {
        removeNotification()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNotification?.key])

  if (!currentNotification) return null

  return (
    <Animated.View
      key={currentNotification.key}
      style={{ ...styles.container, transform: [{ translateY: animated }] }}
    >
      <NotificationsContainer>
        <BannerContent>
          <Text align="center" weight="600">
            {currentNotification.body}
          </Text>
          {/* TODO: */}
          {Boolean(currentNotification.showRefresh) && (
            <Pressable onPress={() => undefined} role="button">
              <Feather name="refresh-ccw" size={20} />
            </Pressable>
          )}
        </BannerContent>
      </NotificationsContainer>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: BANNER,
  },
})

export default Banners
