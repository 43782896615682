import { Platform } from 'react-native'
import { FontDisplay, useFonts } from 'expo-font'

export default function useSpeziaFont() {
  return useFonts(
    Platform.OS === 'web'
      ? {
          'Spezia-Bold': {
            display: FontDisplay.SWAP,
            uri: require('@/assets/fonts/Spezia-Bold.otf'),
          },
          'Spezia-Medium': {
            display: FontDisplay.SWAP,
            uri: require('@/assets/fonts/Spezia-Medium.otf'),
          },
          'Spezia-Regular': {
            display: FontDisplay.SWAP,
            uri: require('@/assets/fonts/Spezia-Regular.otf'),
          },
          'Spezia-SemiBold': {
            display: FontDisplay.SWAP,
            uri: require('@/assets/fonts/Spezia-SemiBold.otf'),
          },
        }
      : {},
  )
}
