import type { DefaultOptions } from '@tanstack/react-query'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'
import { type AxiosError, isAxiosError } from 'axios'

import Sentry from '@/utils/sentry'

import {
  getResponseContext,
  MutationError,
  normalizeKey,
  QueryError,
} from './utils'

const queryCache = new QueryCache({
  onError(error, query) {
    // ignore axios errors since they're handled in 'src/utils/api/interceptors.ts'
    if (isAxiosError(error)) return

    Sentry.captureException(new QueryError(error as Error), (scope) => {
      scope.setLevel('warning')

      const key = normalizeKey(query.queryKey)

      scope.setContext('Query', { key })

      if (typeof error === 'object' && error && 'response' in error) {
        scope.setContext('Response', getResponseContext(error?.response))
      }

      if (typeof key === 'object' && Array.isArray(key)) {
        scope.setFingerprint([
          ...key,
          (error as AxiosError)?.response?.status?.toString() ?? '',
        ])
      }

      return scope
    })
  },
})

const mutationCache = new MutationCache({
  // eslint-disable-next-line max-params
  onError(error, _variables, _context, mutation) {
    // ignore axios errors since they're handled in 'src/utils/api/interceptors.ts'
    if (isAxiosError(error)) return

    Sentry.captureException(new MutationError(error as Error), (scope) => {
      scope.setLevel('warning')

      const key = normalizeKey(mutation.options.mutationKey)

      let variables
      try {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        variables = JSON.stringify(mutation.state.variables, null, 2)
      } catch {
        // Do nothing
      }

      scope.setContext('Mutation', { key, variables })

      try {
        scope.setContext(
          'Response',
          getResponseContext((error as AxiosError)?.response),
        )
      } catch {
        void 0
      }

      if (typeof key === 'object' && Array.isArray(key)) {
        scope.setFingerprint([
          ...key,
          (error as AxiosError)?.response?.status?.toString() ?? '',
        ])
      }

      return scope
    })
  },
})

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const ONE_HOUR = 1000 * 60 * 60
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const ONE_MINUTE = 1000 * 60

const defaultOptions = {
  mutations: {
    networkMode: 'offlineFirst',
  },
  queries: {
    cacheTime: ONE_HOUR,
    networkMode: 'offlineFirst',
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    staleTime: ONE_MINUTE,
  },
} as DefaultOptions

const queryClient = new QueryClient({
  defaultOptions,
  mutationCache,
  queryCache,
})

export default queryClient
