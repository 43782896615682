import { useState } from 'react'
import { StyleSheet, useWindowDimensions, View } from 'react-native'
import type { ReactNode } from 'react'
import styled from 'styled-components/native'

import Button, { type ButtonType } from '@/components/Button'
import { P } from '@/components/Text'

import { Colors, maxWidth } from '@/utils/constants/theme'
import { CornerRadius, Space } from '@/utils/constants/theme'

type AlertButton = {
  text?: string
  onPress?: () => Promise<void> | void
  type?: ButtonType
}

const Container = styled.View<{ $variant?: 'morado' }>`
  background-color: ${({ $variant, theme }) =>
    $variant ? theme.colors.primary : theme.colors.background};
  padding: ${Space.Medium.px};
  border-radius: ${CornerRadius.Xlarge.px};
  width: 100%;
`

type Props = {
  body?: string
  buttons?: AlertButton[]
  onClose?: () => void
  children?: ReactNode
  /** @deprecated */
  variant?: 'morado'
}

const NO_BUTTONS = [{ type: 'default' } as AlertButton]

function Alert(props: Props) {
  const { variant, body, buttons = NO_BUTTONS, children, onClose } = props

  const { width } = useWindowDimensions()

  const [disabled, setDisabled] = useState(false)

  return (
    <Container $variant={variant} role="alert">
      {children ?? (
        <>
          {Boolean(body) && (
            <P color={variant === 'morado' ? 'white' : undefined}>{body}</P>
          )}
          <View
            style={
              width > maxWidth.phone
                ? styles.buttonsContainer
                : styles.mobileButtonsContainer
            }
          >
            {buttons?.map((button, index) => (
              <Button
                key={index}
                color={
                  variant === 'morado' ? Colors.light.secondary : undefined
                }
                onPress={async () => {
                  if (button.onPress) {
                    try {
                      setDisabled(true)
                      // isAwaitable
                      await button.onPress()
                    } finally {
                      if (disabled) setDisabled(false)
                    }

                    onClose?.()
                  } else {
                    onClose?.()
                  }
                }}
                textColor={
                  !button.type || button.type === 'default'
                    ? 'white'
                    : undefined
                }
                disabled={disabled}
                type={button.type}
              >
                {button.text ?? 'OK'}
              </Button>
            ))}
          </View>
        </>
      )}
    </Container>
  )
}

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row-reverse',
    gap: Space.Small.n,
    justifyContent: 'flex-start',
    marginTop: Space.Medium.n,
  },
  mobileButtonsContainer: {
    flexDirection: 'column',
    gap: Space.Small.n,
    marginTop: Space.Medium.n,
  },
  space: { width: Space.Small.n },
})

export { AlertButton }
export default Alert
