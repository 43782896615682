import { useCallback, useEffect, useRef } from 'react'
import {
  Animated,
  PanResponder,
  Platform,
  useWindowDimensions,
} from 'react-native'
import { StyleSheet } from 'react-native'
import Constants from 'expo-constants'
import type { ComponentProps, ReactNode } from 'react'
import {
  SafeAreaProvider,
  useSafeAreaInsets,
} from 'react-native-safe-area-context'

import { OVERLAY_COLOR } from '@/utils/constants/colors'
import { kavBehavior } from '@/utils/constants/platform'
import { Space, useIsMobile } from '@/utils/constants/theme'
import { ALERT, MODAL } from '@/utils/constants/zIndex'

import { KeyboardAvoidingView } from '../Carousel/styled'

import ModalHeading from './Heading'
import {
  Container as AnimatedContainer,
  Content as AnimatedContent,
} from './styled'
import { useEscapeKeyDown } from './utils'

type Props = {
  isAlert?: boolean
  visible?: boolean
  heading?: ComponentProps<typeof ModalHeading>
  children: ReactNode
  level?: number
  hideHeading?: boolean
  /**
   * By default, modals will adjust their size to the content.
   * If this prop is set to true, the modal will take the full screen.
   */
  isFullSize?: boolean
  /**
   * By default, modals will adjust their size to the content.
   * If this prop is set to true, the modal will take the medium
   * available screen space.
   */
  isMidSize?: boolean
  onDismiss?: () => void
  variant?: 'morado'
  showCloseButton?: boolean
}

const styles = StyleSheet.create({
  safeArea: {
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 999,
  },
})

export const MODAL_LEVEL_PADDING = 10
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const HEIGHT_PORTION_TO_DISMISS = 1 / 4

export default function Modal(props: Props) {
  const {
    isAlert,
    children,
    level,
    heading,
    visible = true,
    onDismiss,
    hideHeading = false,
    isFullSize,
    isMidSize,
    variant,
    showCloseButton,
  } = props

  const animation = useRef(new Animated.Value(0)).current
  const levelPadding = useRef(
    new Animated.Value(Constants.statusBarHeight),
  ).current

  const { height } = useWindowDimensions()
  const isMobile = useIsMobile()
  const { bottom } = useSafeAreaInsets()

  useEscapeKeyDown({
    onEvent: () => {
      animateModal(-1, onDismiss)
    },
  })

  const animateModal = useCallback(
    (direction: 1 | -1 = 1, callback?: () => void) => {
      Animated.spring(animation, {
        friction: 15,
        tension: 150,
        toValue: height * direction,
        useNativeDriver: Platform.OS !== 'web',
      }).start(callback)
    },
    [animation, height],
  )

  useEffect(() => {
    animateModal()
  }, [animateModal])

  useEffect(() => {
    levelPadding.setValue(
      Constants.statusBarHeight + MODAL_LEVEL_PADDING * (level ?? 0),
    )
  }, [level, levelPadding])

  const isSwipeEnabled = !isAlert && Platform.OS !== 'web'

  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: () => isSwipeEnabled,
      onPanResponderMove: (_, gestureState) => {
        if (gestureState.dy < 0) return
        animation.setValue(height - gestureState.dy)
      },
      onPanResponderRelease(_, gestureState) {
        if (gestureState.dy > height * HEIGHT_PORTION_TO_DISMISS) {
          animateModal(-1, onDismiss)
        } else {
          animateModal()
        }
      },
      onPanResponderTerminate: () => {
        animateModal()
      },
    }),
  ).current

  const onRightItemPress = useCallback(() => {
    animateModal(-1, onDismiss)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animateModal])

  if (!visible) return null

  return (
    <SafeAreaProvider style={styles.safeArea}>
      <AnimatedContainer
        style={{
          backgroundColor: animation.interpolate({
            inputRange: [0, height],
            outputRange: ['rgba(0, 0, 0, 0)', OVERLAY_COLOR],
          }),
          justifyContent: isMobile ? 'flex-end' : 'center',
          padding: Space.Small.n,
          paddingBottom: Math.max(bottom, Space.Small.n),
          zIndex: isAlert ? ALERT : MODAL,
        }}
        role={isAlert ? 'alertdialog' : 'dialog'}
      >
        {/* Top spacing for modal levels */}
        {Boolean(levelPadding && isMobile) && (
          <Animated.View
            style={{ flexShrink: 0, minHeight: levelPadding }}
            aria-hidden
          />
        )}
        <KeyboardAvoidingView
          behavior={kavBehavior}
          keyboardVerticalOffset={Space.Small.n}
          style={{ backgroundColor: 'transparent' }}
        >
          <AnimatedContent
            style={{
              transform: [
                {
                  translateY: animation.interpolate({
                    inputRange: [0, height],
                    outputRange: [height, 0],
                  }),
                },
              ],
            }}
            $isAlert={isAlert}
            $isFullSize={isFullSize}
            $isMidSize={isMidSize}
            $isMobile={isMobile}
            $variant={variant}
          >
            {!hideHeading && (
              <ModalHeading
                {...panResponder.panHandlers}
                onRightItemPress={onRightItemPress}
                showCloseButton={showCloseButton}
                swipeable={isSwipeEnabled}
                variant={variant}
                {...heading}
              />
            )}
            {children}
          </AnimatedContent>
        </KeyboardAvoidingView>
      </AnimatedContainer>
    </SafeAreaProvider>
  )
}
