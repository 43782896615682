import { useQuery } from '@tanstack/react-query'

import { axiosInstance } from '@/utils/api'
import { applicationKeys } from '@/utils/api/application'
import type { APIResponse } from '@/utils/api/types'
import { getResponseData } from '@/utils/api/utils'
import { MissingApplicationId } from '@/utils/errors'
import { getCurrentApplicationId } from '@/utils/zustand'

export type IssuerQuestion = {
  name: string
  metadata: {
    type: 'SINGLE_SELECT' | 'MULTI_SELECT' | 'TEXT'
    required: boolean
    category: string
    values: string[]
    translations: Record<string, string>
    conditions: string
    placeholder?: Record<string, string>
  }
  issuers: string[]
}

export type IssuerQuestions = {
  applicant: IssuerQuestion[]
  vehicle: IssuerQuestion[]
  driver: IssuerQuestion[]
}

export type IssuerQuestionsResponse = APIResponse<IssuerQuestions>

export function getIssuerQuestionsMetadata(
  applicationId: string,
  params?: { rateCall15Issuer: string },
) {
  if (!applicationId) throw new MissingApplicationId()

  return axiosInstance
    .get<IssuerQuestionsResponse>(
      `/autoFlow/applications/${applicationId}/issuerQuestions/metadata`,
      { params },
    )
    .then(getResponseData)
}

export function useGetIssuerQuestionsMetadata(
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  params?: Parameters<typeof getIssuerQuestionsMetadata>[1],
) {
  return useQuery({
    queryFn: () =>
      getIssuerQuestionsMetadata(getCurrentApplicationId() as string, params),
    queryKey: applicationKeys.issuerQuestionsMetadata(params),
  })
}
