/* eslint-disable simple-import-sort/imports */

/**
 * We should import these first to ensure that they are loaded before
 * anything else.
 */
import '@/utils/polyfills'
import '@/utils/sentry'

import App from '@/Root'
import { registerRootComponent } from 'expo'

registerRootComponent(App)
