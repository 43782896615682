import axios from 'axios'
import axiosRetry, {
  exponentialDelay,
  isNetworkOrIdempotentRequestError,
} from 'axios-retry'

import { IdempotencyKeyHeaderName } from '@/utils/api/idemPotencyKeys'
import { getIdemPotencyKey } from '@/utils/api/idemPotencyKeys'
import { API_URL, HttpStatus } from '@/utils/constants'
import Sentry from '@/utils/sentry'

import './react-query'

import {
  requestInterceptor,
  responseErrorInterceptor,
  responseFulfilledInterceptor,
} from './interceptors'

const axiosInstance = axios.create({
  baseURL: `${API_URL}/api/v1`,
  withCredentials: true,
})

const MAX_RETRIES_COUNT = 5

axiosRetry(axiosInstance, {
  onRetry(retryCount, error, requestConfig) {
    if (requestConfig.headers?.[IdempotencyKeyHeaderName]) {
      Sentry.addBreadcrumb({
        data: { error, retryCount },
        message: 'Retrying request with new Idempotency-Key',
      })

      requestConfig.headers[IdempotencyKeyHeaderName] = getIdemPotencyKey()
    }
  },
  retries: MAX_RETRIES_COUNT,
  retryCondition(error) {
    const isTooManyRequests =
      error.response?.status === HttpStatus.TooManyRequests

    const willRetry =
      isTooManyRequests || isNetworkOrIdempotentRequestError(error)

    return willRetry
  },
  retryDelay(retryCount, error) {
    const headers = error.response?.headers
    const retryAfterSeconds = Number(
      headers?.['retry-after'] || headers?.['Retry-After'],
    )

    if (!isNaN(retryAfterSeconds)) return retryAfterSeconds

    return exponentialDelay(retryCount)
  },
})

axiosInstance.interceptors.response.use(
  responseFulfilledInterceptor,
  responseErrorInterceptor,
)

axiosInstance.interceptors.request.use(requestInterceptor)

export { axiosInstance, getIdemPotencyKey }
export { getIdemPotencyKeyHeader } from '@/utils/api/idemPotencyKeys'
