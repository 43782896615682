import React, { useCallback, useMemo } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import Feather from '@expo/vector-icons/Feather'
import FontAwesome from '@expo/vector-icons/FontAwesome6'
import { useNavigation } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import * as Sharing from 'expo-sharing'
import { useTranslation } from 'react-i18next'

import type {
  RootStackParamList,
  RootStackScreenProps,
} from '@/navigation/types'

import LoadingScreen from '@/modules/LoadingScreen'

import Card from '@/components/Card'
import Content from '@/components/Content'
import MasterList from '@/components/MasterList'
import { P } from '@/components/Text'

import * as analytics from '@/utils/analytics'
import { Events } from '@/utils/analytics/events'
import { useReferralLink } from '@/utils/api/customer'
import COLORS from '@/utils/constants/colors'
import { Space } from '@/utils/constants/theme'
import useColorScheme from '@/utils/hooks/useColorScheme'

import Family from '@/assets/svg/family'

type NavigationType = RootStackScreenProps<
  keyof RootStackParamList
>['navigation']

/**
 * Returns a function that resets the navigation history, adds the Home screen to it,
 * and then navigates to the Referral screen, so it goes to Home on back press.
 * Can receive an AnalyticsEvent to track the execution of the returned function.
 */
export function useNavigateToReferral(eventId?: Events) {
  const navigation = useNavigation<NavigationType>()

  return useCallback(() => {
    if (eventId) {
      analytics.track({ id: eventId })
    }
    navigation.reset({
      index: 1,
      routes: [{ name: 'TabNavigator' }, { name: 'ReferralScreen' }],
    })
  }, [eventId, navigation])
}

export default function ReferralScreen() {
  const { t } = useTranslation()
  const scheme = useColorScheme()

  const { data: referralLink, isLoading } = useReferralLink()

  const iconProps = useMemo(
    () => ({ color: COLORS[scheme].text, size: 20 }),
    [scheme],
  )

  const encodedMessage = encodeURIComponent(
    t('screens.settings.referral.messageTemplate', { referralLink }),
  )

  const handleFBMessenger = useCallback(async () => {
    const appDeepLink = t(
      'screens.settings.referral.facebookMessenger.deepLink',
      { referralLink: encodeURIComponent(referralLink as string) },
    )
    const websiteLink = t('screens.settings.referral.facebookMessenger.link', {
      encodedMessage,
    })
    const hasAppInstalled =
      Platform.OS !== 'web' && (await Linking.canOpenURL(appDeepLink))
    analytics.track({ id: Events.UIClickReferralShareFacebookMessenger })
    if (hasAppInstalled) {
      Linking.openURL(appDeepLink).catch(() => {
        Linking.openURL(websiteLink)
      })
    } else {
      Linking.openURL(websiteLink)
    }
  }, [encodedMessage, referralLink, t])

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <Content withPadding>
      <Card
        contentStyle={styles.cardContent}
        style={styles.card}
        title={t('screens.settings.referral.title')}
        withBorderRadius
      >
        <P>{t('screens.settings.referral.paragraph')}</P>
        <View style={styles.imageContainer}>
          <Family />
        </View>
      </Card>
      <MasterList>
        <MasterList.Item
          onPress={() => {
            analytics.track({ id: Events.UIClickReferralShareSms })
            Linking.openURL(
              t('screens.settings.referral.sms.link', { encodedMessage }),
            )
          }}
          accessory="chevron"
          icon={<Feather {...iconProps} name={'message-circle'} />}
        >
          {t('screens.settings.referral.sms.label')}
        </MasterList.Item>
        <MasterList.Item
          accessory="chevron"
          icon={<FontAwesome {...iconProps} name={'facebook-messenger'} />}
          onPress={handleFBMessenger}
        >
          {t('screens.settings.referral.facebookMessenger.label')}
        </MasterList.Item>
        <MasterList.Item
          onPress={() => {
            analytics.track({ id: Events.UIClickReferralShareWhatsapp })
            Linking.openURL(
              t('screens.settings.referral.whatsapp.link', { encodedMessage }),
            )
          }}
          accessory="chevron"
          icon={<FontAwesome {...iconProps} name={'whatsapp'} />}
        >
          {t('screens.settings.referral.whatsapp.label')}
        </MasterList.Item>
        <MasterList.Item
          onPress={() => {
            analytics.track({ id: Events.UIClickReferralShareMore })
            Sharing.shareAsync(referralLink as string)
          }}
          accessory="chevron"
          icon={<Feather {...iconProps} name={'share-2'} />}
        >
          {t('screens.settings.referral.more.label')}
        </MasterList.Item>
      </MasterList>
    </Content>
  )
}

const styles = StyleSheet.create({
  card: {
    marginBottom: Space.Medium.n,
    padding: Space.Medium.n,
    paddingBottom: 0,
  },
  cardContent: {
    gap: Space.Medium.n,
  },
  imageContainer: {
    alignItems: 'center',
  },
})
