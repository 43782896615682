import { fetchAuthSession } from 'aws-amplify/auth'
import type {
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { isAxiosError } from 'axios'
import pDebounce from 'p-debounce'

import { isEmpty, UUID_REGEX, WEBFLOW_COLLECTION_ID_REGEX } from '@/utils'
import { getResponseData } from '@/utils/api/utils'
import { getIdToken } from '@/utils/auth'
import { HttpStatus } from '@/utils/constants'
import Sentry from '@/utils/sentry'
import { getCurrentSessionId, getHandoff } from '@/utils/zustand'

import type { Application } from '@/.d'

import signOut from '../auth/sign-out'

const IGNORED_ERROR_MESSAGES = [
  'postalCode is not serviceable',
  'invalid postal code',
  'session expired',
  'check sum failed for vin number',
] as const
const PUBLIC_ENDPOINT_REGEX = /\/public\//i // don't include the g flag, see https://stackoverflow.com/questions/4950463/regex-in-javascript-fails-every-other-time-with-identical-input
const LICENSES_VALIDATION = /\/licenses\/[\d\w-]+\/validation/i
const VIN_VALIDATION = /\/vin\/[\d\w-]+\/validation/i
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const IGNORED_RESPONSE_STATUS_CODES_FOR_REPORTING = [
  HttpStatus.Unauthorized,
  HttpStatus.TooManyRequests,
  HttpStatus.NotFound,
] as const

function getMaskedPath(path?: string) {
  if (!path) return ''

  return path
    .replace(UUID_REGEX, '{uuid}')
    .replace(WEBFLOW_COLLECTION_ID_REGEX, '{collection_id}')
    .replace(LICENSES_VALIDATION, '/licenses/{license_number}/validation')
    .replace(VIN_VALIDATION, '/vins/{vin_number}/validation')
}

function handleLogout() {
  signOut({ amplifySignOut: false, redirect: true })
}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const debouncedLogOut = pDebounce(handleLogout, 1500)

export function getResponseErrorMessage(response: AxiosResponse) {
  const responseData = response.data

  if (!responseData) return ''
  if (typeof responseData !== 'object') return String(responseData)

  if (
    'errorMessage' in responseData &&
    typeof responseData.errorMessage === 'string'
  ) {
    return responseData.errorMessage.replace(UUID_REGEX, '')
  }

  return ''
}

function getResponseFingerprint(response: AxiosResponse) {
  return [
    String(response.config.method),
    getMaskedPath(response.config.url),
    String(response.status),
    String(getResponseErrorMessage(response?.data)),
  ]
}

function getIsUserError(response: AxiosResponse | undefined) {
  if (!response) return false

  return (
    response.status < HttpStatus.ServerError &&
    response.status >= HttpStatus.BadRequest
  )
}

function getResponseErrorCode(response: AxiosResponse<unknown>) {
  if (
    response &&
    response.data &&
    typeof response.data === 'object' &&
    'errorCode' in response.data
  )
    return response.data?.errorCode
  return null
}

function getIsIgnoredErrorMessage(response: AxiosResponse) {
  const responseErrorMessage = getResponseErrorMessage(response)

  return IGNORED_ERROR_MESSAGES.some((message) =>
    new RegExp(`^${message}.*`, 'i').test(responseErrorMessage),
  )
}

export function responseFulfilledInterceptor(response: AxiosResponse) {
  const data = getResponseData<Application>(response)

  // Remove data that is not needed, and make comparisons and formik state
  // more lightweight
  if (data?.applicationDataOperations) {
    delete data.applicationDataOperations
  }

  return response
}

export async function responseErrorInterceptor(error: unknown) {
  if (!error) throw error
  if (typeof error !== 'object') throw error

  if (isAxiosError(error)) {
    if (error.response?.status === HttpStatus.Unauthorized) {
      const session = await fetchAuthSession({ forceRefresh: true }).catch(
        () => null,
      )

      if (!session?.credentials) {
        await debouncedLogOut()
        throw error
      }
    }

    if (
      IGNORED_RESPONSE_STATUS_CODES_FOR_REPORTING.includes(
        error.response?.status ?? 0,
      )
    ) {
      throw error
    }

    const response = error?.response

    if (response) {
      error.name = 'API Error'

      const responseErrorMessage = getResponseErrorMessage(response)
      const isIgnoredErrorMessage = getIsIgnoredErrorMessage(response)

      if (isIgnoredErrorMessage) throw error

      const responseErrorCode = getResponseErrorCode(response)

      if (responseErrorMessage || responseErrorCode)
        error.message = responseErrorMessage || responseErrorCode
    }

    // Capture exception in Sentry
    Sentry.captureException(error, (scope) => {
      if (!response) return scope
      if (!response?.config) return scope

      if (getIsUserError(response)) scope.setLevel('warning')

      scope.setFingerprint(getResponseFingerprint(response))

      try {
        scope.setContext('Request', {
          data: JSON.stringify(response.config.data, null, 2),
          isAuthenticated: String(
            Boolean(
              response.config.headers?.Authorization?.toString()?.split(' ')[1]
                .length,
            ),
          ),
          method: response.config.method,
          path: getMaskedPath(response.config.url),
        })
        scope.setContext('Response', {
          data: JSON.stringify(response.data, null, 2),
          error: {
            code: getResponseErrorCode(response),
            message: getResponseErrorMessage(response),
          },
          status: response?.status,
        })
      } catch {
        // ignore
      }

      return scope
    })
  } else if (error instanceof Error) {
    Sentry.captureException(error)
  } else {
    Sentry.captureMessage(String(error))
  }

  throw error
}

export async function requestInterceptor(request: InternalAxiosRequestConfig) {
  // If we're at the public endpoint, send the custom session id
  // we'll do this manually for the link user endpoint
  if (!request.headers) request.headers = {} as AxiosRequestHeaders

  if (!isEmpty(getHandoff())) {
    request.headers['X-RUN-AS-ASSOCIATED-ENTITY-ID'] = getHandoff()?.entityId
    request.headers['X-RUN-AS-USER-TYPE'] = getHandoff()?.userType
  }

  if (PUBLIC_ENDPOINT_REGEX.test(request.url ?? '')) {
    // Retrieve the custom session id from storage
    // if it's not present on the 'global' value
    const sessionId = getCurrentSessionId()

    if (sessionId) {
      request.headers['x-custom-session-id'] = sessionId
    }
  } else {
    const idToken = await getIdToken()

    if (idToken) {
      request.headers['Authorization'] = `Bearer ${idToken}`
    }
  }

  return request
}
