import { Platform } from 'react-native'
import Constants from 'expo-constants'

export const kavBehavior = Platform.OS === 'ios' ? 'padding' : 'height'

export const keyboardAvoidingViewOffset = Platform.select({
  android: Constants.statusBarHeight,
  default: 0,
  ios: Constants.statusBarHeight,
}) as unknown as number
