import { Platform } from 'react-native'

const environment = process.env.EXPO_PUBLIC_ENVIRONMENT as string
const variant = process.env.EXPO_PUBLIC_VARIANT || 'production'

import { appVersion, buildVersion } from '@/utils/constants'

import type { Events } from './events'

export type IdentifyArgs = {
  age?: number
  email?: string
  /** customerId or applicationId */
  userId?: string | null
  gender?: string
  phone?: string
  firstName?: string
  lastName?: string
  language?: string
  vehicleYear?: string
  vehicleMake?: string
  vehicleTrim?: string
  vehicleModel?: string
  maritalStatus?: string
  vehicleOwnership?: string
  address?: Partial<
    Record<'street' | 'city' | 'state' | 'postalCode' | 'country', string>
  >
} & { [flagName: `has${string}`]: string | boolean }

export type AnalyticsEvent = {
  id: Events | `${'ui' | 'error' | 'submit'}__${string}`
  metadata?: Record<string, any>
}

export const appInformation = {
  build: buildVersion,
  environment,
  platform_os: Platform.OS,
  platform_version: Platform.Version,
  variant,
  version: appVersion,
}
