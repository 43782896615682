/* eslint-disable no-var */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-params */

import { useEffect } from 'react'
import { Platform } from 'react-native'

const DELIGHTED_PROJECT_ID = 'YZrOilFc2fN6RFOZ'

export default function useDelighted() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    if (Platform.OS === 'web' && global.window) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      !(function (e: any, t, r, n) {
        if (!e[n]) {
          for (
            var a: any = (e[n] = []),
              i = [
                'survey',
                'reset',
                'config',
                'init',
                'set',
                'get',
                'event',
                'identify',
                'track',
                'page',
                'screen',
                'group',
                'alias',
              ],
              c = 0;
            c < i.length;
            c++
          ) {
            var s = i[c]
            a[s] =
              a[s] ||
              (function (e) {
                return function () {
                  // eslint-disable-next-line prefer-rest-params
                  var t = Array.prototype.slice.call(arguments)
                  a.push([e, t])
                }
              })(s)
          }
          a.SNIPPET_VERSION = '1.0.1'
          var o = t.createElement('script')
          ;(o.type = 'text/javascript'),
            (o.async = !0),
            (o.src =
              'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' +
              r +
              '/' +
              n +
              '.js')
          var [l]: any = t.getElementsByTagName('script')
          l.parentNode.insertBefore(o, l)
        }
      })(window, document, DELIGHTED_PROJECT_ID, 'delighted')
    }
  }, [])

  function showSurvey({
    email,
    name,
    properties,
  }: {
    email?: string
    name?: string
    properties?: Record<string, string | undefined | number>
  }) {
    window.delighted?.survey({
      email,
      name,
      properties,
    })
  }

  return { showSurvey }
}
