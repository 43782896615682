import { useEffect } from 'react'
import { Keyboard } from 'react-native'

import Modal from '@/modules/Modal'

import Alert from '@/components/Alert'

import { useAlertStore } from '@/utils/zustand'

function AlertModal() {
  const alert = useAlertStore((state) => state.current)

  useEffect(() => {
    if (!alert) return

    Keyboard.dismiss()
  }, [alert])

  if (!alert) {
    return null
  }

  return (
    <Modal
      heading={{ title: alert.title }}
      onDismiss={alert.onClose}
      variant={alert.variant}
      isAlert
    >
      <Alert key={alert.title + alert.body} {...alert} />
    </Modal>
  )
}

export default AlertModal
