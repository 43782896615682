import { Platform } from 'react-native'
import type { Breadcrumb } from '@sentry/react'

import { API_URL } from '@/utils/constants'

const nodeEnv = process.env.NODE_ENV

const isDevelopment =
  (typeof process !== 'undefined' && nodeEnv === 'development') ||
  (typeof __DEV__ !== 'undefined' && __DEV__)

// This variable is injected by webpack
export const dsn = process.env.EXPO_PUBLIC_SENTRY_DSN
export const environment = process.env.EXPO_PUBLIC_ENVIRONMENT

export const autoSessionTracking = true

export const tracesSampleRate = 0.33

export const profilesSampleRate = 0.33

export const tracePropagationTargets = ['localhost', new RegExp(API_URL)]

/**
 * We should be sure that we always handle this errors in
 * the UI and respond accordingly and not just ignore them
 */
const IGNORED_ERROR_MESSAGES = [
  'co.ocho.error.auto.driverLicense.invalid',
  'co.ocho.error.auto.vehicle.vin.invalidCharacter',
  'co.ocho.error.auto.vehicle.vin.invalidCheckSum',
  'co.ocho.error.auto.vehicle.vin.invalidLength',
  'co.ocho.error.auto.vehicle.vin.invalidYear',
  'co.ocho.error.payment.debitcard.cardNotEnabledForDebit',
  'postalCode is not serviceable',
]

export function beforeBreadcrumb(breadcrumb: Breadcrumb) {
  if (isDevelopment && breadcrumb.message && Platform.OS === 'web') {
    // eslint-disable-next-line no-console
    console.log(
      `🍞 %cBreadcrumb%c \nMessage: "${breadcrumb.message}"${
        breadcrumb.data ? `\nData: ` : ''
      }`,
      'color: #ff8c00; font-weight: bold',
      'color: #000; font-weight: normal',
    )
    if (breadcrumb.data) {
      // eslint-disable-next-line no-console
      console.dir(breadcrumb.data)
    }
  }

  return breadcrumb
}

export function beforeSend(event: any, hint: any) {
  if (isDevelopment) {
    /* eslint-disable no-console */
    console.warn('⚠️ Captured error')
    console.log(JSON.stringify(event, null, 1))
    console.log(hint)
    /* eslint-enable no-console */
    return null
  }

  if (hint && hint.originalException && hint.originalException.isAxiosError) {
    if (
      hint.originalException.response &&
      hint.originalException.response.data
    ) {
      const contexts = { ...event.contexts }

      const errorResponseData = hint.originalException.response.data

      // Ignore errors for non-serviceable postal codes
      if (
        'errorMessage' in errorResponseData &&
        IGNORED_ERROR_MESSAGES.some((ignoredErrorMessage) =>
          errorResponseData.errorMessage.includes(ignoredErrorMessage),
        )
      ) {
        return null
      }

      contexts.Response = {
        ...(contexts.Response ?? {}),
        data: errorResponseData,
      }

      event.contexts = contexts
    }
  }

  return event
}

export const ignoreErrors: (string | RegExp)[] = [
  /*
    O C H O
    E R R O R S
  */
  'Empty quotes',
  'ChunkLoadError',
  'No credit score',
  'AxiosOrderError',
  'Not authenticated', // Cognito error if user is not authenticated
  /MissingArgumentError/i,
  /CodeMismatchException/i, // Cognito error if verification code is incorrect
  '3000ms timeout exceeded', // Ignore timeout errors, typically font-loading
  /InvalidConfirmationCode/i,
  'The user is not authenticated', // Cognito error if user is not authenticated
  'Incorrect username or password.', // Cognito error for incorrect username or password
  /^postalCode is not serviceable/gi, // Ignore postal code non serviceable errors
  /^.*429$/i, // Ignore any 429 status code

  // Default

  // Safari web extension
  'safari-web-extension://',
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  // Avast extension error
  '_avast_submit',
]

export const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
]
