import { Children, cloneElement, isValidElement } from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import Feather from '@expo/vector-icons/Feather'
import type { ReactNode } from 'react'
import type { GestureResponderEvent } from 'react-native'
import styled from 'styled-components/native'

import Text, { H3 } from '@/components/Text'

import { Colors, CornerRadius } from '@/utils/constants/theme'

const styles = StyleSheet.create({
  item: {
    flex: 1,
  },
  masterList: {
    borderRadius: CornerRadius.Medium.n,
    marginBottom: 10,
    overflow: 'hidden',
  },
})

const ItemContainer = styled.View<{ $isLast?: boolean }>`
  flex: 1;
  display: flex;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.background};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 45px;
  border-color: ${({ theme }) => theme.colors.border};
  border-bottom-width: ${({ $isLast }) => ($isLast ? '0' : `2px`)};
  gap: 10px;
`

type ItemProps = {
  children: ReactNode
  onPress?: (event: GestureResponderEvent) => void
  icon?: ReactNode
  isLast?: boolean
  accessory?: 'chevron' | 'link'
}

function Item({ children, onPress, icon, isLast, accessory }: ItemProps) {
  function renderChildren() {
    if (typeof children === 'string') {
      return <Text style={styles.item}>{children}</Text>
    }

    return children
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {typeof onPress === 'function' ? (
        <ItemContainer
          $isLast={isLast}
          as={Pressable}
          onPress={onPress}
          role="button"
        >
          {icon}
          {renderChildren()}
          {Boolean(accessory) && (
            <Feather
              color={Colors.light.textSecondary}
              name={accessory === 'chevron' ? 'chevron-right' : 'external-link'}
              size={22}
            />
          )}
        </ItemContainer>
      ) : (
        <ItemContainer $isLast={isLast}>{renderChildren()}</ItemContainer>
      )}
    </>
  )
}

function MasterList({
  children,
  header,
}: {
  children: false | JSX.Element | (JSX.Element | false)[]
  header?: string | JSX.Element
}) {
  const totalChildren = Children.count(children)
  const childrenWithProps = Children.map(children, (child, index) => {
    if (isValidElement(child)) {
      return cloneElement(child, { isLast: totalChildren - 1 === index } as any)
    }

    return child
  })

  return (
    <>
      {Boolean(header) && <H3>{header}</H3>}
      <View style={styles.masterList}>{childrenWithProps}</View>
    </>
  )
}

MasterList.Item = Item
MasterList.Header = H3

export default MasterList
