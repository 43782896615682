import * as Haptics from 'expo-haptics'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import type { AlertButton } from '@/components/Alert'

import Sentry from '@/utils/sentry'

const DEFAULT_BANNER_DURATION = 5000

type Banner = {
  key: number
  body: string
  duration: number
  showRefresh?: boolean
}

type Alert = {
  title: string
  body?: string
  onClose: () => void
  /** @deprecated */
  variant?: 'morado'
  buttons?: AlertButton[]
}

export * from './auth'
export * from './modals'

export const usePendingUploadStore = create(
  combine({ value: [] as string[] }, (set) => ({
    add: (uploadId: string) =>
      set((prev) => ({ value: prev.value.concat(uploadId) })),
    remove: (uploadId: string) =>
      set((prev) => ({ value: prev.value.filter((id) => id !== uploadId) })),
  })),
)

export const useAlertStore = create(
  combine({ current: null as Alert | null }, (set) => ({
    remove: () => set({ current: null }),
    show: (
      title: string,
      body?: string,
      options?: Omit<Alert, 'title' | 'body' | 'onClose'>,
    ) => {
      Sentry.addBreadcrumb({
        category: 'ui-event',
        data: { body, options, title },
        message: 'Alert shown',
      })

      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium).catch(
        () => undefined,
      )

      const onClose = () => set({ current: null })
      return set({ current: { body, title, ...options, onClose } })
    },
  })),
)

export function useShowAlert() {
  return useAlertStore(({ show }) => show)
}

export const useChatBadgeStore = create(
  combine({ count: 0 }, (set, state) => ({
    increment: () => set({ count: state().count + 1 }),
    reset: () => set({ count: 0 }),
  })),
)

export const useBannersStore = create(
  combine({ current: [] as Banner[] }, (set, state) => ({
    remove: () => {
      const [, ...newCurrent] = state().current
      return set({ current: newCurrent })
    },
    show: (body: string, options?: Omit<Banner, 'text'>) => {
      Sentry.addBreadcrumb({
        category: 'ui-event',
        data: { body, options },
        message: 'Banner shown',
      })

      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light).catch(
        () => undefined,
      )

      const newBanner = {
        body,
        duration: options?.duration ?? DEFAULT_BANNER_DURATION,
        key: options?.key ?? Date.now(),
      }
      return set({ current: state().current.concat(newBanner) })
    },
  })),
)

export function useShowBanner() {
  return useBannersStore(({ show }) => show)
}
