import { useEffect, useState } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useQueryClient } from '@tanstack/react-query'

import type { RootStackScreenProps } from '@/navigation/types'

import AppError from '@/modules/AppError'
import LoadingScreen from '@/modules/LoadingScreen'

import { setAuthStoreState } from '@/utils/zustand'

type ScreenProps = RootStackScreenProps<'HandoffScreen'>

export default function HandoffScreen() {
  const { params } = useRoute<ScreenProps['route']>()
  const navigation = useNavigation<ScreenProps['navigation']>()
  const queryClient = useQueryClient()

  const [state, setState] = useState({
    error: null as null | unknown,
    isLoading: false,
  })

  useEffect(() => {
    const { idToken, entityId } = params

    if (!idToken) return

    async function loadData() {
      try {
        setState({ error: null, isLoading: true })

        setAuthStoreState({
          customerId: entityId,
          handoff: { entityId, idToken, userType: 'CUSTOMER' },
          // TODO: use real cognito username and user id, used for sentry and analytics
          user: { userId: entityId, username: entityId },
        })

        await queryClient.resetQueries()

        navigation.reset({
          index: 0,
          routes: [{ name: 'TabNavigator' }],
        })
      } catch (error) {
        setState({ error, isLoading: false })
      }
    }

    loadData()
  }, [navigation, params, queryClient])

  if (state.isLoading) {
    return <LoadingScreen message="Handing-off..." />
  }

  if (state.error) {
    return <AppError />
  }

  return <LoadingScreen />
}
